'use client'

import React, { createContext, useState } from 'react'
import { useCookies } from 'react-cookie'
export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const [cookie, setCookie] = useCookies(['token'])
  const [isAuthentiCated, setIsAuthentiCated] = useState(cookie.token ? true : false)
  const [user, setUser] = useState({})

  return (
    <GlobalContext.Provider
      value={{
        auth: [isAuthentiCated, setIsAuthentiCated],
        user: [user, setUser],
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
export default GlobalContext
